<template>
  <div class="flex xs6">
    <va-card
      :style="computedStyle"
      :class="computedClass"
      :title="getControlAreaTitle()"
      :subtitle="getControlAreaSubtitle()"
      :card-badges="cardBadges"
    >

      <template slot="actions">
        <!-- <va-button flat small icon="at-icon l_settings1" @click="settingsModal = !settingsModal"/> -->
        <router-link :to="{ name: 'area-details', params: {roomId: getControlGroupId(), areaId: controlAreaId, displayName: controlAreaName, roomName: controlGroupName }}">
          <va-button flat small icon="at-icon card_plus"/>
        </router-link>
      </template>
      <div class="row">
        <div class="flex xs12">
          <div class="row">
            <!-- Product -->
            <div class="flex xs5">
              <template>
                <div class="content">
                  <div class="content__main">
                    <div class="content__main__top" style="font-size: 1.3rem;">
                      <va-icon name="at-icon l_bag"></va-icon>
                      <span class="workorder__title">{{ getWorkOrderName()}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- Key -->
            <div class="flex xs4">
              <template>
                <div class="content">
                  <div class="content__main">
                    <div class="content__main__top title" style="font-size: 1.3rem;">
                      <va-icon name="at-icon l_barcode2"></va-icon>
                      <span class="workorder__title">{{ workOrderKey }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- Light ON/OFF -->
            <div class="flex xs3">
              <template>
                <div class="content">
                  <div class="content__main">
                    <div class="content__main__top title" style="font-size: 1.3rem;">
                      <va-icon :name="getLightsIcon()"></va-icon>
                      <span class="workorder__title">{{ lightNowState.toUpperCase() }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- WorkOrder Progress -->
          <div class="flex xs12">
            <template>
              <div class="content">
                <div class="content__main">
                  <div class="title mb-3"></div>
                  <va-progress-bar v-model="growingProgress.percent">
                    <span :style="{visibility: workOrderStartDate ? 'visible' : 'hidden'}">{{ Math.floor(growingProgress.percent) }}%</span>
                  </va-progress-bar>
                  <div class="content__main__bottom">
                    <span v-if="workOrderStartDate">{{ $t('growing.progress') }} {{ growingProgress.currentDay }}/{{ growingProgress.daysTotal }} {{ $t('timeData.days')}}</span>
                    <span v-else>{{ $t('growing.progress') }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </va-card>

    <va-modal
      v-model="settingsModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="settingsModal"
    >

      <template slot="actions">
        <div class="row">
          <div class="flex xs4">
          </div>
          <div class="flex xs8 text-right">
            <va-button @click="cancel()" flat color="gray">
              {{  $t('modal.cancel') }}
            </va-button>
            <va-button @click="confirm()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </div>
      </template>

      <at-settings-form
        :title="`${$t('growing.settings')}`"
        :subtitle="controlGroupName + ' - ' + controlAreaName"
        :settings-array="settings"
      />

    </va-modal>
  </div>
</template>

<script>
import AtSettingsForm from './../../at-settings-form/AtSettingsForm'

export default {
  name: 'growing-ca-card',
  data () {
    return {
      growingProgress: {},
      settingsModal: false,
    }
  },
  props: {
    controlGroupId: {
      type: Number,
    },
    controlAreaId: {
      type: Number,
      default: 0,
    },
    controlAreaName: {
      type: String,
      default: 'Area',
    },
    controlGroupName: {
      type: String,
      default: '',
    },
    lightNowState: {
      type: String,
      default: '',
    },
    workOrderName: {
      type: String,
      default: 'Work order',
    },
    workOrderKey: {
      type: String,
    },
    workOrderStartDate: {
      type: String,
    },
    workOrderEndDate: {
      type: String,
    },
    settings: {
      type: Object,
      default () {
        return {}
      },
    },
    cardBadges: {
      type: Array,
    },
  },
  created () {
    this.getGrowingProgress()
  },
  methods: {
    getControlGroupId () {
      return this.controlGroupId ? this.controlGroupId : parseInt(this.$route.params.roomId)
    },
    getControlAreaTitle () {
      return this.controlAreaName
    },
    getControlAreaSubtitle () {
      return this.workOrderStartDate === null ? '' : this.workOrderName
    },
    getGrowingProgress () {
      let daysTotal = 0
      let currentDay = -1
      let daysLeft = 0
      let percentage = 0

      if (this.workOrderStartDate === null || this.workOrderEndDate === null) {
        daysTotal = null
        currentDay = -1
        daysLeft = null
        percentage = 0
      } else {
        const start = new Date(this.workOrderStartDate + 'Z')
        start.setUTCHours(0, 0, 0)
        const end = new Date(this.workOrderEndDate + 'Z')
        end.setUTCHours(23, 59, 59)
        const secTotal = (end - start) / 1000 // s
        const secPassed = (new Date() - start) / 1000
        const secLeft = secTotal - secPassed

        if (secLeft < 0) { // already ended
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = daysTotal
          daysLeft = 0
          percentage = 100
        } else if (secPassed > secTotal) { // not started
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = -1
          daysLeft = 0
          percentage = 0
        } else { // growing now
          daysTotal = Math.floor(secTotal / (24 * 60 * 60))
          currentDay = Math.floor(secPassed / (24 * 60 * 60))
          daysLeft = daysTotal - currentDay
          percentage = Math.floor(secPassed / secTotal * 100)
        }
      }
      this.growingProgress = {
        daysTotal: daysTotal < 0 ? 0 : daysTotal,
        currentDay: currentDay,
        daysLeft: daysLeft,
        percent: percentage,
      }
    },
    getWorkOrderName () {
      if (this.workOrderName.length > 27) {
        const splited = this.workOrderName.split(' ')
        let newWorkOrderName = ''
        for (let i = 0; i < splited.length; i++) {
          const aux = newWorkOrderName + ' ' + splited[i]
          if (aux.length < 27 && splited[i]) {
            newWorkOrderName = newWorkOrderName + ' ' + splited[i] + ' '
          } else {
            return newWorkOrderName.slice(0, -1) + '...'
          }
        }
      }
      return this.workOrderName
    },
    getLightsIcon () {
      if (this.lightNowState) {
        return this.lightNowState.toUpperCase() === 'ON' ? 'at-icon l_ledson' : 'at-icon l_ledsoff'
      } else {
        return ''
      }
    },
    cancel () {
      this.$refs.settingsModal.cancel()
    },
    confirm () {
      this.$emit('saveControlAreaSettings', this.controlGroupId, this.controlAreaId)
      this.$refs.settingsModal.ok()
    },
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger', // change to warning from ros
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
  },
  components: {
    AtSettingsForm,
  },
}
</script>

<style lang="scss">
.content__main__top title {
  border: none;
  margin-bottom: 0;
}

.workorder__title {
  color: hsl(var(--primary-color-hue), 40%, 30%);
}
</style>
