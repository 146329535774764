<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="controlGroupData && !loading">
      <h1 class="mb-3"> {{ controlGroupTitle }} </h1>
      <div class="cards">
        <div class="cards-container row d-flex wrap align--start">
          <data-card v-for="(dataName, index) in airDataNames" :key="'A' + index"
            :id="controlGroupId"
            :data-name="dataName"
            :data-icon="getDataIcon(dataName)"
            :data-now-level="currentAirData[dataName].level"
            :data-now-val="currentAirData[dataName].val"
            :data-now-units="currentAirData[dataName].units"
            :data-now-ref="currentAirData[dataName].ref"
            :data-last-hour-avg="airDataLastHour[dataName].avg"
            :data-last-hour-var="airDataLastHour[dataName].var"
            :data-last-hour-min="airDataLastHour[dataName].min"
            :data-last-hour-max="airDataLastHour[dataName].max"
            :data-last-hour-trend="airDataLastHour[dataName].trend"
            :chart-title="chartTitle"
            :chart-type="chartType"
            :chart-data="airChartData[dataName]"
            :chart-options="airChartOptions[dataName]"
            :is-expandable="true && (currentAirData[dataName].level !== 4)"
          />
          <div class="flex xs12"></div>
          <template>
            <div v-if="irrigationMapLoading" class="flex sm12 lg12">
              <div class="text--center pb-4">
                <div class="flex-center spinner-box">
                  <spinner/>
                </div>
              </div>
            </div>
            <at-irrigation-map v-else
              :mapName="$t('irrigation.irrigationMap')"
              :timeline-start-date="irrigationMapStartDate"
              :timeline-end-date="irrigationMapEndDate"
              :duration-days="irrigationMapDurationDays"
              :work-order-info="workOrderInfo"
              :irrigation-map="irrigationMap"
              :editable="false"
              :data-point="'day'"
              context="room"
            />
          </template>
          <growing-ca-card v-for="area in controlAreasData" :key="area.id"
            :control-group-id="area.control_group_id"
            :control-area-id="area.id"
            :control-area-name="area.name"
            :control-group-name="controlGroupTitle"
            :work-order-name="area.work_order_name"
            :work-order-key="area.work_order_key"
            :work-order-start-date="area.work_order_start_date"
            :work-order-end-date="area.work_order_end_date"
            :light-now-state="currentLightData[area.id].light_state"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import * as socketio from '../../../utilities/socketio'
import { hex2rgb } from '../../../services/vuestic-ui'
import { styleHelpers } from '@/services/atmosphere-ui'
import { getTimeFormat, getTimeDateFormat, getTimeStampDay, getLocalNowTimeDate, getUTCtimeString } from '@/services/atmosphere-ui/helpers/TimeHelpers'
import { chartLineSettings, chartBarSettings, getChartOptions } from '@/data/chartSettings'
import DataCard from '../../../services/atmosphere-ui/components/cards/at-data-card/AtDataCard'
import GrowingCaCard from '../../../services/atmosphere-ui/components/cards/at-growing-ca-card/AtGrowingCaCard'

export default {
  name: 'growing-room-card-view',
  data () {
    return {
      loading: false,
      notFound: false,
      irrigationMapLoading: false,
      airDataNames: ['temperature', 'relative_humidity', 'co2'],
      // airDataNames: ['temperature', 'relative_humidity', 'co2', 'dew_point', 'volume_flow', 'vpd'],
      controlGroupData: null,
      controlAreasData: [],
      currentAirData: {},
      currentLightData: {},
      airDataLastHour: {},
      airDataDailyVariation: [],
      airDataHourlyVariation: [],
      airDataQuarterHourlyVariation: [],
      airChartData: {},
      airChartOptions: {},
      irrigationMap: {},
      start: null,
      end: null,
      selectedInvertal: { start: null, end: null, dataPoint: null },
      enableSlider: false,
      settings: {},
    }
  },
  created () {
    this.$level.value = 'bird'
    this.$root.$on('selectedInvertal', this.onSelectedInterval)
    this.$store.commit('setTimelineMinDate', null)
    this.initializeView()
  },
  computed: {
    activeFarm () {
      return this.$farmId ? this.$farmId : parseInt(process.env.VUE_APP_FARM_ID)
    },
    controlGroupId () {
      return this.controlGroupData ? this.controlGroupData.id : parseInt(this.$route.params.roomId)
    },
    controlGroupTitle () {
      return this.controlGroupData ? this.controlGroupData.name : ''
    },
    chartTitle () {
      if (this.selectedInvertal.dataPoint === 'day') {
        return this.$t('growing.charts.daily_variation')
      } else if (this.selectedInvertal.dataPoint === 'hour') {
        return this.$t('growing.charts.hourly_variation')
      } else if (this.selectedInvertal.dataPoint === '15min') {
        return this.$t('growing.charts.quater_hourly_variation')
      }
      return this.$('growing.charts.variation')
    },
    chartType () {
      if (this.selectedInvertal.dataPoint === 'day') {
        return 'vertical-bar'
      } else if (this.selectedInvertal.dataPoint === 'hour' || this.selectedInvertal.dataPoint === '15min') {
        return 'line'
      }
      return 'line'
    },
    irrigationMapStartDate () {
      const first = this.controlAreasData.reduce((previous, current) => {
        if ((previous.work_order_end_date === '' && current.work_order_end_date === '') || (previous.work_order_end_date === null && current.work_order_end_date === null)) {
          return current
        } else if (previous.work_order_start_date === '' || previous.work_order_start_date === null || previous.work_order_name === 'Semina/taglio finto') {
          return current
        } else if (current.work_order_start_date === '' || current.work_order_start_date === null || current.work_order_name === 'Semina/taglio finto') {
          return previous
        }
        return moment(previous.work_order_start_date).diff(moment(current.work_order_start_date)) <= 0 ? previous : current
      })
      if (first.work_order_start_date) {
        return first.work_order_start_date
      } else {
        return moment.utc().subtract(4, 'days').hours(0).minutes(0).format().slice(0, -1)
      }
    },
    irrigationMapEndDate () {
      const last = this.controlAreasData.reduce((previous, current) => {
        if ((previous.work_order_end_date === '' && current.work_order_end_date === '') || (previous.work_order_end_date === null && current.work_order_end_date === null)) {
          return current
        } else if (previous.work_order_end_date === '' || previous.work_order_end_date === null || previous.work_order_name === 'Semina/taglio finto') {
          return current
        } else if (current.work_order_end_date === '' || current.work_order_end_date === null || current.work_order_name === 'Semina/taglio finto') {
          return previous
        }
        return (moment(previous.work_order_end_date).diff(moment(current.work_order_end_date)) >= 0 ? previous : current)
      })
      if (last.work_order_start_date) {
        return last.work_order_end_date
      } else {
        return moment.utc().format().slice(0, -1)
      }
    },
    irrigationMapDurationDays () {
      return moment(this.irrigationMapEndDate).diff(this.irrigationMapStartDate, 'days') + 1
    },
    workOrderInfo () {
      let info = []
      for (let i = 0; i < this.controlAreasData.length; i++) {
        info = [
          ...info,
          {
            id: this.controlAreasData[i].id,
            name: this.controlAreasData[i].name,
            work_order_id: this.controlAreasData[i].work_order_id,
            work_order_key: this.controlAreasData[i].work_order_key,
            work_order_name: this.controlAreasData[i].work_order_name,
            work_order_start_date: this.controlAreasData[i].work_order_start_date,
            work_order_end_date: this.controlAreasData[i].work_order_end_date,
            production_growing_recipe: this.controlAreasData[i].production_growing_recipe,
          },
        ]
      }
      return { [this.controlGroupId]: info }
    },
  },
  methods: {
    getLineChartOptions (dataname, min, max) {
      return {
        ...getChartOptions('Time'),
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.$t(`data.${dataname}`),
            },
            ticks: {
              suggestedMin: Math.floor(min),
              suggestedMax: Math.ceil(max + max * 0.05),
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.$t('timeData.time') + ' (' + moment().tz(this.$farmTimezone).zoneAbbr() + ')',
            },
          }],
        },
      }
    },
    getBarChartOptions (dataname) {
      return {
        ...getChartOptions('Day'),
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.$t(`data.${dataname}`),
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 1,
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: '',
            },
          }],
        },
      }
    },
    initializeView () {
      this.loading = true
      socketio.initialiseSocket()
      socketio.socket.emit('get_one_control_group', this.activeFarm, this.controlGroupId, { per_page: 15 })
      socketio.socket.once('get_one_control_group_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
          this.notFound = true
        } else {
          if (parsed.data.id === this.controlGroupId) {
            this.controlGroupData = parsed.data.data
            this.controlAreasData = (this.controlGroupData.control_areas).sort((a, b) => (a.id > b.id ? 1 : -1))
            this.initializeDataCards()
          }
        }
      })
      let now = new Date()
      let daysBefore = new Date(now.getTime() - (96 * 60 * 60 * 1000) * 1)
      this.start = daysBefore
      this.end = now
      now = now.toISOString().slice(0, -1)
      daysBefore = daysBefore.toISOString().slice(0, -1)

      this.selectedInvertal = {
        start: new Date(getLocalNowTimeDate().getTime() - (25 * 60 * 60 * 1000) * 1),
        end: getLocalNowTimeDate(),
        dataPoint: 'hour',
      }
      this.onSelectedInterval(this.selectedInvertal)
    },
    initializeDataCards () {
      for (let i = 0; i < this.airDataNames.length; i++) {
        this.currentAirData = {
          ...this.currentAirData,
          [this.airDataNames[i]]: {},
        }
        this.airDataLastHour = {
          ...this.airDataLastHour,
          [this.airDataNames[i]]: {},
        }
        this.airChartData = {
          ...this.airChartData,
          [this.airDataNames[i]]: {},
        }
        this.airChartOptions = {
          ...this.airChartOptions,
          [this.airDataNames[i]]: {
            ...getChartOptions(''),
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.$t(`data.${this.airDataNames[i]}`),
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 0,
                },
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.$t('timeData.time') + ' (' + moment().tz(this.$farmTimezone).zoneAbbr() + ')',
                },
              }],
            },
          },
        }
      }
      for (let i = 0; i < this.controlAreasData.length; i++) {
        this.currentLightData = {
          ...this.currentLightData,
          [this.controlAreasData[i].id]: { light_state: '' },
        }

        this.settings = {
          ...this.settings,
          [this.controlAreasData[i].id]: this.controlAreasData[i].settings,
        }

        this.listenLightCurrentData(this.controlAreasData[i].id)
      }

      this.loading = false
      this.getIrrigationMap(this.irrigationMapStartDate, this.irrigationMapEndDate)
      this.listenAirCurrentData()
      this.listenAirHourlyDataUpdate()
      this.listenIrrigationMapUpdate()

      // this.listenAirHourlyDataUpdate()
    },
    listenAirCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`control_group_${this.controlGroupId}`])
      socketio.socket.on('control_group_air_conditions_current_update', data => {
        const parsed = JSON.parse(data).data
        const parsedData = parsed.data
        for (let i = 0; i < this.airDataNames.length; i++) {
          this.currentAirData[this.airDataNames[i]] = parsedData[this.airDataNames[i]]
        }
      })
    },
    getAirDailyData (start, end) {
      socketio.socket.emit('get_control_group_air_conditions_daily', this.controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_air_conditions_daily_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log('[ERROR (HOURLY conditions response)] ' + parsed.message.error)
        } else {
          const parsedData = parsed.data
          if (parsedData.total > 0) {
            this.airDataDailyVariation.splice(0)
            for (let i = 0; i < parsedData.data.length; i++) {
              this.airDataDailyVariation.push(parsedData.data[i])
            }
            this.getChartAirData()
          }
        }
      })
      this.getChartAirData()
    },
    listenAirHourlyDataUpdate () {
      socketio.socket.on('control_group_air_conditions_hourly_update', data => {
        const parsed = JSON.parse(data).data
        const parsedData = parsed.data
        for (let i = 0; i < this.airDataNames.length; i++) {
          this.airDataLastHour[this.airDataNames[i]] = parsedData[this.airDataNames[i]]
        }
        this.getChartAirData()
      })
    },
    getAirHourlyData (start, end) {
      socketio.socket.emit('get_control_group_air_conditions_hourly', this.controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_air_conditions_hourly_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log('[ERROR (HOURLY conditions response)] ' + parsed.message.error)
        } else {
          const parsedData = parsed.data
          if (parsedData.total > 0) {
            this.airDataHourlyVariation.splice(0)
            const diff = (new Date() - new Date(end + 'Z')) / (60 * 60 * 1000)
            if (Math.floor(diff) < 2) {
              this.airDataLastHour = parsedData.data[parsedData.total - 1]
            }
            for (let i = 0; i < parsedData.data.length; i++) {
              this.airDataHourlyVariation.push(parsedData.data[i])
            }
            this.getChartAirData()
          }
        }
      })
    },
    getAirQuarterHourlyData (start, end) {
      socketio.socket.emit('get_control_group_air_conditions_quarter_hourly', this.controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_air_conditions_quarter_hourly_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log('[ERROR (HOURLY conditions response)] ' + parsed.message.error)
        } else {
          const parsedData = parsed.data
          if (parsedData.total > 0) {
            this.airDataQuarterHourlyVariation.splice(0)
            for (let i = 0; i < parsedData.data.length; i++) {
              this.airDataQuarterHourlyVariation.push(parsedData.data[i])
            }
            this.getChartAirData()
          }
        }
      })
    },
    listenLightCurrentData (controlAreaId) {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`control_area_${controlAreaId}`])
      socketio.socket.on('control_area_light_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_area_id === controlAreaId) {
            const parsedData = parsed.data.data
            this.currentLightData = {
              ...this.currentLightData,
              [parsed.data.control_area_id]: {
                light_state: parsedData.light_state,
              },
            }
          }
        }
      })
    },
    getChartAirData () {
      let values = []
      if (this.selectedInvertal.dataPoint === 'day') {
        values = this.airDataDailyVariation
      } else if (this.selectedInvertal.dataPoint === 'hour') {
        values = this.airDataHourlyVariation
      } else if (this.selectedInvertal.dataPoint === '15min') {
        values = this.airDataQuarterHourlyVariation
      }
      if (values.length > 0) {
        for (let i = 0; i < this.airDataNames.length; i++) {
          if (this.selectedInvertal.dataPoint === 'day') {
            const historyDay = values.map(element => {
              return (
                element[`${this.airDataNames[i]}_day`].avg
              )
            })
            const historyNight = values.map(element => {
              return (
                element[`${this.airDataNames[i]}_night`].avg
              )
            })

            this.airChartData[this.airDataNames[i]] = {
              labels: values.map(element => {
                return getTimeStampDay(element.time_stamp)
              }),
              datasets: [
                {
                  label: 'Night',
                  backgroundColor: hex2rgb(this.$themes.primary, 0.6).css,
                  ...chartBarSettings.night,
                  data: historyNight,
                },
                {
                  label: 'Day',
                  backgroundColor: hex2rgb(this.$themes.secondary, 0.6).css,
                  ...chartBarSettings.day,
                  data: historyDay,
                },
              ],
            }
            this.airChartOptions[this.airDataNames[i]] = this.getBarChartOptions(this.airDataNames[i])
          } else {
            const history = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                  y: element[this.airDataNames[i]].avg,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                }
              )
            })

            const maximums = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                  y: element[this.airDataNames[i]].max,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                }
              )
            })
            const reference = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                  y: element[this.airDataNames[i]].ref,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                }
              )
            })
            const minimums = values.map(element => {
              return (
                {
                  x: getTimeFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                  y: element[this.airDataNames[i]].min,
                  day: getTimeDateFormat(moment(element.time_stamp + 'Z'), 'medium', true),
                }
              )
            })

            this.airChartData[this.airDataNames[i]] = {
              labels: values.map(element => {
                return this.selectedInvertal.dataPoint === 'day' ? getTimeStampDay(element.time_stamp) : getTimeFormat(moment(element.time_stamp + 'Z'), 'short', true)
              }),
              datasets: [
                {
                  label: 'Max',
                  ...chartLineSettings.max,
                  data: maximums,
                },
                {
                  label: 'Average',
                  ...chartLineSettings.average,
                  backgroundColor: hex2rgb(this.$themes.primary, 0.6).css,
                  data: history,
                },
                {
                  label: 'Reference',
                  ...chartLineSettings.reference,
                  data: reference,
                },
                {
                  label: 'Min',
                  ...chartLineSettings.min,
                  data: minimums,
                },
              ],
            }
            const min = Math.min.apply(Math, history.map(element => {
              return element.y
            }))

            const max = Math.max.apply(Math, history.map(element => {
              return element.y
            }))
            this.airChartOptions[this.airDataNames[i]] = this.getLineChartOptions(this.airDataNames[i], min, max)
          }
        }
      } else {
        for (let i = 0; i < this.airDataNames.length; i++) {
          this.airChartData[this.airDataNames[i]] = {
            labels: [],
            datasets: [],
          }
        }
      }
    },
    getDataIcon (dataName) {
      return styleHelpers.growingIcon('air', dataName)
    },
    getIrrigationMap (start, end) {
      this.irrigationMapLoading = true
      socketio.initialiseSocket()
      socketio.socket.emit('get_control_group_irrigation_map', this.activeFarm, this.controlGroupId, { from: start, to: end })
      socketio.socket.once('get_control_group_irrigation_map_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_group_id === this.controlGroupId) {
            if (parsed.data.data[this.controlGroupId]) {
              this.irrigationMap = parsed.data
              this.irrigationMapLoading = false
            }
          }
        }
      })
    },
    listenIrrigationMapUpdate () {
      socketio.initialiseSocket()
      socketio.socket.on('control_group_irrigation_map_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_group_id === this.controlGroupId) {
            this.getIrrigationMap(this.irrigationMapStartDate, this.irrigationMapEndDate)
          }
        }
      })
    },
    onSelectedInterval: function (interval) {
      this.selectedInvertal = interval
      this.enableSlider = true

      if (this.selectedInvertal.dataPoint === 'day') {
        this.getAirDailyData(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
      } else if (this.selectedInvertal.dataPoint === 'hour') {
        this.getAirHourlyData(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
      } else if (this.selectedInvertal.dataPoint === '15min') {
        this.getAirQuarterHourlyData(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
      }
    },
    saveControlAreaSettings (controlGroupId, controlAreaId) {
      console.log('save_control_area_settings', this.activeFarm, controlGroupId, controlAreaId)

      this.validateControlAreaSettings(controlAreaId)
      console.log(this.settings[controlAreaId])
      // socketio.socket.emit('save_control_group_settings', this.activeFarm, controlGroupId, this.settings[controlAreaId])
    },
    validateControlAreaSettings (controlAreaId) {
      for (const settingFamily in this.settings[controlAreaId]) {
        for (const setting in this.settings[controlAreaId][settingFamily]) {
          if (this.settings[controlAreaId][settingFamily][setting].type === 'time-seconds') {
            this.settings[controlAreaId][settingFamily][setting].value = moment.duration(this.settings[controlAreaId][settingFamily][setting].timeSecondsString).asSeconds()
            delete this.settings[controlAreaId][settingFamily][setting].timeSecondsString
          } else if (this.settings[controlAreaId][settingFamily][setting].type === 'bool') {
            this.settings[controlAreaId].light[setting].value = this.settings[controlAreaId][setting].valueSelected.value

            delete this.settings[controlAreaId][settingFamily][setting].valueSelected
          }
        }
      }
    },
  },
  watch: {
    notFound () {
      if (this.notFound) {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
  },
  components: {
    DataCard,
    GrowingCaCard,
  },
  beforeDestroy () {
    this.$root.$off('selectedInvertal', this.onSelectedInterval)
    socketio.socket.emit('unsubscribe_from_context', [`control_group_${this.controlGroupId}`])
    for (let i = 0; i < this.controlAreasData.length; i++) {
      socketio.socket.emit('unsubscribe_from_context', [`control_area_${this.controlAreasData[i].id}`])
    }
  },
}
</script>
